import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row, Accordion, Card } from 'react-bootstrap';
import * as Globals from '../../globals';
import TryItBanner from '../components/tryItBanner/tryItBanner';
import rhythm from "../utils/rhythm";

const ForAdvisorsPage = ({ data }) => {
  let headerBackgroundFluidImageStack = [
    data.contentfulSiteAdvisors.headerBackground.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo 
        title={data.contentfulSiteAdvisors.metaContent.title} 
        description={data.contentfulSiteAdvisors.metaContent.description}
      />
      <BackgroundImage
        fluid={headerBackgroundFluidImageStack}
        className={`jumbotron section-background pt-0`}
        Tag={`header`}
      >
        <Container style={{ zIndex: 1, position: 'relative' }}>
          <Row>
            <Col md={10} lg={8}>
              <h1 className={`mb-2`}>
                {data.contentfulSiteAdvisors.headerHeadline}
              </h1>
              <h3>{data.contentfulSiteAdvisors.headerSubheadline}</h3>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>

      <section className={`my-5`}>
        <Container>
          <Row className={`mb-4`}>
            <Col xs={12} md={10}>
              <h2>{data.contentfulSiteAdvisors.featuresHeadline}</h2>
            </Col>
          </Row>

          {data.contentfulSiteAdvisors.features.map((feature, index) => {
            return (
              <Row className={`mb-4`} key={index}>
                <Col xs={4} md={2} className={`mx-auto mx-md-0`}>
                  <div
                      className={`mx-auto ml-md-auto`}
                      style={{ width: rhythm(2) }}
                  >
                    <img
                        src={feature.image.file.url}
                        key={feature.image.id}
                        alt={`alt text`}
                    />
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <div className={`mb-4 mt-3 mt-md-0 px-3 pl-md-0`}>
                    <h3 className={`h4`} key={feature.headline}>
                      {feature.headline}
                    </h3>
                    <div
                        key={feature.subheadline.id}
                        dangerouslySetInnerHTML={{
                          __html: feature.subheadline.childMarkdownRemark.html,
                        }}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}

          <div className={`text-center`}>
            <a href={`https://app.boardhealthscore.com/`} target={`_blank`} className={`btn btn-primary`}>
              {/*{data.contentfulSiteAdvisors.featuresButton}*/}
              Get Started
            </a>
          </div>
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`my-5`} id={`faq`}>
        <Container>
          <Row className={`mb-4`}>
            <Col xs={12}>
              <h2 className={`text-center`}>
                {data.contentfulSiteAdvisors.faqHeadline}
              </h2>
            </Col>
          </Row>

          <Row className={`justify-content-center`}>
            <Col md={10} lg={8} className={`d-flex flex-column`}>
              <Accordion>
                {data.contentfulSiteAdvisors.faqQuestions.map((faq, index) => {
                  return (
                    <Card key={index}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={faq}
                        key={faq.question}
                      >
                        {faq.question}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={faq}>
                        <Card.Body
                          key={faq.answer.id}
                          dangerouslySetInnerHTML={{
                            __html: faq.answer.childMarkdownRemark.html,
                          }}
                        />
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      <TryItBanner />
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulSiteAdvisors {
      metaContent {
        title
        description
      }
      headerHeadline
      headerSubheadline
      headerBackground {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      featuresHeadline
      featuresButton
      features {
        headline
        subheadline {
          childMarkdownRemark {
            html
          }
        }
        image {
          file {
            url
          }
        }
      }
      faqHeadline
      faqQuestions {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default ForAdvisorsPage;
